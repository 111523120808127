.modal-box::-webkit-scrollbar {
  width: 12px;
}

.modal-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modal-box::-webkit-scrollbar-thumb {
  background: #018181;
}

.modal-box::-webkit-scrollbar-thumb:hover {
  background: #018181;
}

.modal-box {
  scrollbar-width: none; /* hide */
  scrollbar-color: #018181 #f1f1f1;
}
