.carousel {
  position: relative;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: calc(100% * 3);
}

.carousel-item {
  flex: 1 0 33.333%;
  box-sizing: border-box;
}
