.loading-custom {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  overflow-y: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(121, 121, 121, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}