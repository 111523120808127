.navbar {
  /* @apply min-h-[70px]; */

  .navbar-center {
    .menu {
      /* @apply min-h-[55px]; */
    }
  }
}

/* Style 1 */
.menu-item-wrapper {
  /* @apply p-2 h-full; */

  padding: 0.5rem 0;
  margin: 0 0.5rem;

  &.active {
    /* @apply border-solid border-white border-b-2; */

    transition: transform 1s ease-in-out;
  }

  .menu-label {
    /* @apply ml-2 mr-2 cursor-pointer; */

    svg {
      height: 0.6em;
      width: 0.6em;
    }
  }
}

/* hover */
.menu-item-wrapper:hover {
  /* @apply border-solid border-white border-b-2; */
}

/* Style 2 */
/* .menu-item-wrapper-2 {
  @apply p-2 h-full;

  padding: 0.5rem 0;
  margin: 0 0.5rem;

  &.active {
    @apply border-solid border-white border-b-2;

    transition: transform 1s ease-in-out;
  }

  .menu-label {
    @apply ml-2 mr-2 cursor-pointer;

    svg {
      height: 0.6em;
      width: 0.6em;
    }
  }
} */

/* .menu-item-wrapper-2:hover {
  @apply border-solid border-white border-b-2;
} */

/* sub menu */
.custom-sub-menu {
  /* @apply min-h-80 z-10 shadow-md absolute hidden lg:grid; */

  /* transition: transform 1s ease-in-out; */
  transition: transform 1s ease-in-out;

  &.min-top {
    @apply fixed;

    padding-top: 30px;

    .custom-sub-menu-item {
      /* @apply min-h-20 bg-gray-50 mt-[1px]; */
    }
  }
}

/* mobile menu */
.custom-mobile-menu {
  /* @apply w-full h-full min-h-96 bg-gray-50 mt-1 z-10 absolute shadow-md divide-y lg:hidden; */

  .custom-mobile-menu-item {
    /* &.active {
      @apply text-blue-500 font-bold border-b-indigo-500;
    } */
  }

  .custom-mobile-menu-item:hover {
    /* @apply text-blue-500; */
  }

  .custom-mobile-menu-contact {
    /* @apply pt-5; */
  }
}

/* icon */
.expand-menu, .custom-phone-icon {
  svg {
    width: 0.8em;
    height: 0.8em;
  }
}

/* Mobile menu */
.mobile-menu-container {
  /* @apply pr-1; */
  
  .drawer-side {
    .menu {
      /* @apply pt-10; */
    }
  }
}
