.cover-container {
  /* @apply pt-0; */

  .cover-image {
    /* @apply hero min-h-screen; */
  }

  .cover-bg {
    /* @apply hero-overlay bg-stone-300; */
  }

  .cover-content {
    /* @apply hero-content text-neutral-content text-center bg-white absolute -bottom-7 shadow-lg rounded-md w-3/5 min-w-80; */

    .content {
      /* @apply w-4/5 py-5; */

      .search-content {
        /* @apply mb-5; */

        .input-search-content {
          /* @apply w-full input-bordered rounded-3xl bg-gray-100 relative inline-block lg:pr-48; */
          
        }
        .input-search-content:focus {
          outline: none;
        }

        .btn-search-content {
          /* @apply w-1/5 rounded-3xl btn bg-blue-800 text-white; */

        }
        .btn-search-content:hover {
          /* @apply bg-white text-blue-800 border-blue-800; */
        }
      }

      label {
        /* @apply text-gray-800 cursor-pointer text-sm hover:font-semibold; */
      }
    }
  }
}